import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useAuth from 'hooks/useAuth';
import Logo from 'components/Logo';
import { Theme } from 'theme';
import { SIDEBAR_WIDTH } from 'constants/ui';
import { FormattedMessage } from 'react-intl';
import { sideBarRoutes } from './sidebarRoutes';
import NavItem from './NavItem';
import useCustomBreakpoint from 'hooks/useCustomBreakpoint';

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Docs {
  href: string;
  target: string;
}

interface Section {
  items: Item[];
  subheader: string;
  docsHelp?: Docs;
}

// DONE: Винести конфігурацію в окремий файл.
// DONE: Зробити логіку змінних наборів навігації залежно від ролі користувача.

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      {items.reduce((acc: any[], item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(
      {
        path: item.href ?? 'never',
        end: false,
      },
      pathname,
    );

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaperDesktop: {
    width: SIDEBAR_WIDTH,
    display: 'none',
    [theme.breakpoints.up('desktop')]: {
      top: 64,
      height: 'calc(100% - 64px)',
      display: 'flex',
    },
  },
  drawerPaperMobile: {
    width: SIDEBAR_WIDTH,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

interface DefaultProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const NavBar: FC<DefaultProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const userRoles: string[] = user?.roles!;
  const desktopUp = useCustomBreakpoint('desktop');

  const routeSideBarManager = (userRoles: string[]): Section[] => {
    if (!user) return [];

    if (userRoles.includes('admin')) {
      return sideBarRoutes.admin;
    }
    if (userRoles.includes('designer')) {
      return sideBarRoutes.designer;
    }
    if (userRoles.includes('support')) {
      return sideBarRoutes.support;
    }
    if (userRoles.includes('representative')) {
      return sideBarRoutes.representative;
    }
    if (userRoles.includes('accountant')) {
      return sideBarRoutes.accountant;
    }
    if (userRoles.includes('director')) {
      return sideBarRoutes.director;
    }
    if (userRoles.includes('consultant')) {
      return sideBarRoutes.consultant;
    }
    if (userRoles.includes('manager')) {
      return sideBarRoutes.manager;
    }
    if (userRoles.includes('production')) {
      return sideBarRoutes.production;
    }
    if (userRoles.includes('atelier')) {
      return sideBarRoutes.atelier;
    }
    if (userRoles.includes('distributor')) {
      return sideBarRoutes.distributor;
    }
    if (userRoles.includes('mounter')) {
      return sideBarRoutes.mounter;
    }
    if (userRoles.includes('hanger')) {
      return sideBarRoutes.hanger;
    }
    if (userRoles.includes('courier')) {
      return sideBarRoutes.courier;
    }
    if (userRoles.includes('intern')) {
      return sideBarRoutes.intern;
    }
    return [];
  };

  const navConfig: Section[] = routeSideBarManager(userRoles);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {!desktopUp ? (
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              {/* Варіації логотипу та відповідне їм значення ширини v5 = w224, v6 = w200 */}
              <Logo width={224} />
            </RouterLink>
          </Box>
        ) : null}
        <Divider />
        <Box p={2}>
          {navConfig.map(config => (
            <Box key={config.subheader}>
              <List
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    <FormattedMessage id={config.subheader} defaultMessage={config.subheader} />
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname,
                })}
              </List>
              {config.docsHelp && (
                <>
                  <Divider />
                  <Box p={2}>
                    <Typography variant="body2" color="textPrimary">
                      <FormattedMessage id="nav.needHelp" />
                    </Typography>
                    <Link variant="body2" color="primary" href={config.docsHelp.href} target={config.docsHelp.target}>
                      <FormattedMessage id="nav.documentation" />
                    </Link>
                  </Box>
                </>
              )}
            </Box>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaperMobile }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Drawer classes={{ paper: classes.drawerPaperDesktop }} open variant="persistent">
        {content}
      </Drawer>
    </>
  );
};

export default NavBar;
