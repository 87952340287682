import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Menu as MenuIcon } from 'react-feather';

import Logo from 'components/Logo';
import useCustomBreakpoint from 'hooks/useCustomBreakpoint';
import { THEMES } from 'theme';
import type { Theme } from 'theme';
import Account from './Account';
import Settings from './Settings';
import { topBarPortalId } from './TopBarPortal';

interface DefaultProps {
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      zIndex: theme.zIndex.drawer + 100,
      ...(theme.name === THEMES.LIGHT
        ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main,
          }
        : {}),
    },
    box: {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      marginTop: '6px',
      '& > svg': {
        cursor: 'pointer',
      },
    },
  };
});

const TopBar: FC<DefaultProps> = ({ onMobileNavOpen }) => {
  const classes = useStyles();
  const desktopUp = useCustomBreakpoint('desktop');

  return (
    <AppBar color="primary" className={classes.root}>
      <Toolbar>
        {desktopUp ? (
          <RouterLink to="/">
            {/* Варіації логотипу та відповідне їм значення висоти v5_light = 32, v6_light = 60 */}
            <Logo light height={32} />
            {/*  Варіації логотипу та відповідне їм значення висоти (з білим прямокутником підкладки) v5 = 32, v6 = 50 */}
            {/* <Box bgcolor="#ffffff" paddingBottom={0} paddingTop={1} paddingLeft={2} paddingRight={2}>
              <Logo height={32} />
            </Box> */}
          </RouterLink>
        ) : (
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        )}
        <Box ml={2} flexGrow={1} />
        <div id={topBarPortalId} />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
