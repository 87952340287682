import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  light?: boolean;
  [key: string]: any;
}

const Logo: FC<LogoProps> = ({ light, ...other }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <img alt="Logo" src={`/static/logo${light ? '_light' : ''}.svg`} {...other} />;
};

export default Logo;
